<template>
  <div>
    <el-card>
      <div class="global-search-single">
        <div class="single-left">
          <el-button type="primary" v-if="hasPerm(['admin.role.store'])" @click="handleIncrement">添加角色</el-button>
        </div>
        <div class="single-right">
          <div class="search-item">
            <label class="search-label">角色名称：</label>
            <el-input type="text" v-model="searchInfo.role_name" placeholder="请输入关键词查找" />
          </div>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </div>
      </div>
      <!-- table -->
      <div class="table">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="role_name" label="角色名称" width="220">
          </el-table-column>
          <el-table-column prop="desc" label="描述" min-width="240">
          </el-table-column>
          <!-- <el-table-column prop="" label="成员数">
        </el-table-column>
        <el-table-column prop="" label="成员">
        </el-table-column> -->
          <el-table-column prop="created_at" label="创建日期" width="220">
          </el-table-column>
          <el-table-column prop="found_user_name" label="创建者" width="220">
          </el-table-column>
          <el-table-column label="操作" width="220">
            <template slot-scope="scope">
              <span class="oper" @click="handleModify(scope.row)"
                v-if="scope.row.is_admin != 1 && hasPerm(['admin.role.update'])">编辑</span>
              <el-divider direction="vertical" v-if="scope.row.is_admin != 1"></el-divider>
              <span class="oper" @click="handleDelete(scope.row)"
                v-if="scope.row.is_admin != 1 && hasPerm(['admin.role.delete'])">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- pagintion -->
        <div class="table_foot d-flex justify-content-between">
          <div class="foot_left"></div>
          <div class="main_pages">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 30, 40]"
              layout="total,sizes, prev, pager, next, jumper" :pager-count="5" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </el-card>




  </div>
</template>

<script>
import { Message } from 'element-ui';
import common from '../../common/common';

export default {
  data() {
    return {
      tableData: [],//tableData
      searchInfo: {
        role_name: "",
      },//搜索项
      // page
      pageInfo: {
        page: 1,
        limit: 10
      },
      total: 0,//总条数
      searchObj: {
        search: [],
        order: [
          {
            "key": "created_at",
            "value": "desc"
          }
        ]
      },//搜索数据
    }
  },
  created() {
    this.getRoleList();
  },
  methods: {
    // 添加角色
    handleIncrement() {
      this.$router.push('/setup/management/role');
    },
    // 获取角色列表
    async getRoleList() {
      this.$set(this.searchObj, 'search', common.handleSearch(this.searchInfo, 'equal'));
      const { data: { list, total } } = await this.$get(this.$apis.roleList, { ...this.searchObj, ...this.pageInfo });
      this.$set(this, 'total', total);
      this.$set(this, 'tableData', list);
    },
    // 搜索
    handleSearch() {
      this.getRoleList();
    },
    // 清空事件
    handleClear() {
      this.getRoleList();
    },
    // pageSize
    handleSizeChange(limit) {
      this.$set(this, 'pageInfo', { ...this.pageInfo, limit });
      this.getRoleList();
    },
    // pageNum
    handleCurrentChange(page) {
      this.$set(this, 'pageInfo', { ...this.pageInfo, page });
      this.getRoleList();
    },
    // 编辑
    handleModify(row) {
      this.$router.push({ path: "/setup/management/role", query: { id: row.id } })
    },
    // 删除
    handleDelete(row) {
      this.$confirm(`确定要将角色名称【${row.role_name}】永久删除吗?`, "警告", {
        type: "warning"
      }).then(() => {
        this.$delete(`${this.$apis.deletrole}/${row.id}`).then(res => {
          Message[(() => res.code == 200 ? 'success' : 'error')()]({
            message: `${res.code == 200 ? '删除角色成功!' : `${res.message}`}`
          })
          this.handleSearch();
        })
      }).catch(err => {
        // Message[(() => err == 'cancel' ? 'info' : 'error')()]({
        //   message: `${err == 'cancel' ? '已取消删除!' : error}`
        // })
      })
    }
  }
}

</script>

<style scoped lang="less">
.table {
  padding-top: 20px;

  & /deep/.el-table__header {
    height: 50px !important;
    background: #F9F9FC !important;

    & tr th {
      background: #F9F9FC !important;

      & .cell {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding: 0 16px;
      }
    }
  }

  & /deep/.el-table__body-wrapper.is-scrolling-none table tbody>tr {
    // height: 76px !important;

    & .cell {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      padding: 0 16px;
    }
  }

  & .el-divider--vertical {
    margin: 0 8px
  }

  & .oper {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: var(--fontColor);
    cursor: pointer;
  }
}

.table_foot {
  margin-top: 20px;
}
</style>